import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Space, Popconfirm, notification } from 'antd';

const AppointmentList = () => {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all'); // Filter by 'pending' or 'completed'

  // Fetch appointments from the API
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get('https://api.bookarenthouse.com/api/appointments');
        const sortedAppointments = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by date, recent first
        setAppointments(sortedAppointments);
        setFilteredAppointments(sortedAppointments); // Initialize with all appointments
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAppointments();
  }, []);

  // Format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Handle status change to 'Completed' (Optimistic Update)
  const handleComplete = async (id) => {
    // Optimistic update: Update the appointment status immediately in the UI
    setAppointments(prevAppointments => {
      return prevAppointments.map(appointment =>
        appointment._id === id ? { ...appointment, status: 'completed' } : appointment
      );
    });
    setFilteredAppointments(prevAppointments => {
      return prevAppointments.map(appointment =>
        appointment._id === id ? { ...appointment, status: 'completed' } : appointment
      );
    });

    try {
      // Make the API request to update the status
      await axios.put(`https://api.bookarenthouse.com/api/appointments/${id}/status`, { status: 'completed' });
      notification.success({ message: 'Appointment marked as completed!' });
    } catch (error) {
      console.error('Error updating status:', error);
      // Rollback the optimistic update if the request fails
      setAppointments(prevAppointments => {
        return prevAppointments.map(appointment =>
          appointment._id === id ? { ...appointment, status: 'pending' } : appointment
        );
      });
      setFilteredAppointments(prevAppointments => {
        return prevAppointments.map(appointment =>
          appointment._id === id ? { ...appointment, status: 'pending' } : appointment
        );
      });
      notification.error({ message: 'Failed to update status!' });
    }
  };

  // Handle appointment deletion (Optimistic Update)
  const handleDelete = async (id) => {
    // Optimistic update: Remove the appointment from the UI immediately
    setAppointments(prevAppointments => prevAppointments.filter(appointment => appointment._id !== id));
    setFilteredAppointments(prevAppointments => prevAppointments.filter(appointment => appointment._id !== id));

    try {
      // Make the API request to delete the appointment
      await axios.delete(`https://api.bookarenthouse.com/api/appointments/${id}`);
      notification.success({ message: 'Appointment deleted!' });
    } catch (error) {
      console.error('Error deleting appointment:', error);
      // Rollback the optimistic delete if the request fails
      setAppointments(prevAppointments => {
        // Fetch the appointment data again to restore it
        const deletedAppointment = appointments.find(app => app._id === id);
        return [...prevAppointments, deletedAppointment];
      });
      setFilteredAppointments(prevAppointments => {
        const deletedAppointment = appointments.find(app => app._id === id);
        return [...prevAppointments, deletedAppointment];
      });
      notification.error({ message: 'Failed to delete appointment!' });
    }
  };

  // Handle filter change (Pending or Completed)
  const handleFilterChange = (status) => {
    setStatusFilter(status);
    if (status === 'pending') {
      setFilteredAppointments(appointments.filter(app => app.status === 'pending'));
    } else if (status === 'completed') {
      setFilteredAppointments(appointments.filter(app => app.status === 'completed'));
    } else {
      setFilteredAppointments(appointments); // Show all appointments
    }
  };

  // Columns for Ant Design Table
  const columns = [
    {
      title: 'S.No',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Date',
      render: (text, record) => formatDate(record.created_at),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space size="middle">
          {record.status !== 'completed' && (
            <Button
              type="primary"
              onClick={() => handleComplete(record._id)}
              style={{ borderRadius: '4px' }}
            >
              Complete
            </Button>
          )}
          <Popconfirm
            title="Are you sure you want to delete this appointment?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger style={{ borderRadius: '4px' }}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={styles.container}>
      <h5 style={styles.header}>Appointments</h5>
      <div style={styles.buttonGroup}>
        <Button
          type={statusFilter === 'pending' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('pending')}
          style={styles.filterButton}
        >
          Pending
        </Button>
        <Button
          type={statusFilter === 'completed' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('completed')}
          style={styles.filterButton}
        >
          Completed
        </Button>
        <Button
          type={statusFilter === 'all' ? 'primary' : 'default'}
          onClick={() => handleFilterChange('all')}
          style={styles.filterButton}
        >
          All
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredAppointments}
        rowKey="_id"
        pagination={{ pageSize: 5 }}
        style={styles.table}
      />
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '20px',
    fontWeight: '600',
  },
  buttonGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'flex-start',
  },
  filterButton: {
    borderRadius: '4px',
    padding: '8px 16px',
    fontWeight: '500',
    minWidth: '100px',
    textAlign: 'center',
    boxShadow: 'none',
  },
  table: {
    width: '100%',
    boxSizing: 'border-box',
  },
};

export default AppointmentList;
