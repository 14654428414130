// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute';
import SignInPage from './Components/SigninpageComponent';
import HomePageComponent from './Components/HomePageComponent';
import Profile from './Components/ProfileComponent';
import LocationForm from './Components/LocationformComponent';
import PropertyPage from './Components/PropertiesPageComponent';
import Agentsform from './Components/AgentsformComponent';
import Appointmentlist from './Components/Appointmentslist';
import FeedbackForm from './Components/FeedbackComponent';
import PhotoGallery from './Components/PhotogalleryComponent';
import Dashboard from './Components/Dashboard';
import UserList from './Components/UserlistComponent';
import PaymentList from './Components/PaymentHistory';
import Bannerdata from './Components/BannerComponent';
import DaysInputForm from './Components/PropertyTimeline';
import OccupancyRequests from './Components/PropertyOccupency';
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/home" element={<HomePageComponent />}>
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="location"
          element={
            <ProtectedRoute>
              <LocationForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="properties"
          element={
            <ProtectedRoute>
              <PropertyPage />
            </ProtectedRoute>
          }
        />
            <Route
              path="Propertytimeline"
              element={
                <ProtectedRoute>
                  <DaysInputForm />
                </ProtectedRoute>
              }
            />
        <Route
          path="agentform"
          element={
            <ProtectedRoute>
              <Agentsform />
            </ProtectedRoute>
          }
        />
        <Route
          path="appointmentlist"
          element={
            <ProtectedRoute>
              <Appointmentlist />
            </ProtectedRoute>
          }
        />
        <Route
          path="feedback"
          element={
            <ProtectedRoute>
              <FeedbackForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="photogallery"
          element={
            <ProtectedRoute>
              <PhotoGallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="userlist"
          element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          }
        />
     <Route
  path="payments"
  element={
    <ProtectedRoute>
      <PaymentList />
    </ProtectedRoute>
  }
/>
<Route
  path="bannerdata"
  element={
    <ProtectedRoute>
      < Bannerdata/>
    </ProtectedRoute>
  }
/>
<Route
  path="occupencyrequest"
  element={
    <ProtectedRoute>
      < OccupancyRequests/>
    </ProtectedRoute>
  }
/>
</Route>

    </Routes>
  );
};

export default App;
