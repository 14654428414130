import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message, Tag, Modal } from 'antd';
import axios from 'axios';

const OccupancyRequests = () => {
  const [occupancyRequests, setOccupancyRequests] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null); // State to store property details
  const [loading, setLoading] = useState(false);
  const hosturl = 'https://api.bookarenthouse.com';

  useEffect(() => {
    fetchOccupancyRequests();
  }, []);

  const fetchOccupancyRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${hosturl}/occupancy-requests`);
  
      if (response.status === 200 && response.data.data) {
        setOccupancyRequests(response.data.data);
      } else {
        message.warning(response.data.message || 'No occupancy requests found.');
      }
    } catch (error) {
      if (error.response) {
        // If the backend returned an error (e.g., 404 or 500)
        message.error(`Error: ${error.response.data.message || 'Failed to fetch occupancy requests'}`);
      } else {
        // If the request was made but no response was received
        message.error('Network error: Unable to reach the server');
      }
      console.error('Error fetching occupancy requests:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchUserDetails = async (userId) => {
    if (!userId) {
      message.error('User ID is invalid');
      return;
    }
  
    try {
      const response = await axios.get(`${hosturl}/api/app-users`);
      const user = response.data.find(user => user._id === userId);
  
      if (user) {
        setUserDetails(user);
      } else {
        message.error('User not found');
      }
    } catch (error) {
      message.error('Failed to fetch user details');
      console.error('Error fetching user details:', error);
    }
  };

  const fetchPropertyDetails = async (propertyId) => {
    if (!propertyId) {
      message.error('Property ID is invalid');
      return;
    }
    
    try {
      const response = await axios.get(`${hosturl}/api/properties/${propertyId}`);
      setPropertyDetails(response.data); // Set the fetched property data to state
    } catch (error) {
      message.error('Failed to fetch property details');
      console.error('Error fetching property details:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/occupancy-requests/${id}`);
      message.success('Occupancy request deleted successfully');
      fetchOccupancyRequests(); // Re-fetch the list after deletion
    } catch (error) {
      message.error('Failed to delete occupancy request');
      console.error('Error deleting occupancy request:', error);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      await axios.put(`${hosturl}/occupancy-requests/${id}/status`, { status: newStatus });
      message.success(`Occupancy request status changed to ${newStatus}`);
      fetchOccupancyRequests(); // Re-fetch the list after status update
    } catch (error) {
      message.error('Failed to change status');
      console.error('Error changing status:', error);
    }
  };

  const columns = [
    {
      title: 'Sender ID',
      dataIndex: ['userId', 'email'],
      key: 'userId',
      render: (userId) => (
        <Button type="link" onClick={() => fetchUserDetails(userId)}>
          {userId}
        </Button>
      ),
    },
    {
      title: 'Owner Id',
      dataIndex: ['ownerId', 'email'],
      key: 'ownerEmail',
      render: (ownerId) => (
        <Button type="link" onClick={() => fetchUserDetails(ownerId)}>
          {ownerId}
        </Button>
      ),
    },
    {
      title: 'Property ID',
      dataIndex: ['propertyId', '_id'],
      key: 'propertyId',
      render: (propertyId) => (
        <Button type="link" onClick={() => fetchPropertyDetails(propertyId)}>
          {propertyId}
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = 'geekblue';
        if (status === 'occupied') color = 'green';
        else if (status === 'paid') color = 'volcano';
        else if (status === 'canceled') color = 'red';
        else color = 'blue';

        return <Tag color={color}>{status}</Tag>;
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div>
          {record.status === 'requested' && (
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              onClick={() => handleStatusChange(record._id, 'occupied')}
            >
              Mark as Occupied
            </Button>
          )}
          {record.status === 'occupied' && (
            <Button
              style={{ marginRight: 8 }}
              type="danger"
              onClick={() => handleStatusChange(record._id, 'paid')}
            >
              Mark as Paid
            </Button>
          )}
          {record.status === 'paid' && (
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              onClick={() => handleStatusChange(record._id, 'occupied')}
            >
              Mark as Occupied
            </Button>
          )}
          {record.status === 'canceled' || (record.status === 'paid' && !record._id) && (
            <Button
              style={{ marginRight: 8 }}
              disabled
            >
              Action Completed
            </Button>
          )}

          <Popconfirm
            title="Are you sure you want to delete this request?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // Modal to display user details
  const userDetailsModal = (
    <Modal
      visible={!!userDetails}
      title="User Details"
      onCancel={() => setUserDetails(null)}
      footer={null}
    >
      {userDetails ? (
        <div>
          {/* <h4>User Information</h4> */}
          {/* <p><strong>ID:</strong> {userDetails._id}</p> */}
          <p><strong>Username:</strong> {userDetails.username}</p>
          <p><strong>Email:</strong> {userDetails.email}</p>
          <p><strong>Phone:</strong> {userDetails.phoneNumber}</p>

          {/* <h5>Address</h5> */}
          <p><strong>House No:</strong> {userDetails?.address?.houseNo || 'N/A'}</p>
          <p><strong>Area:</strong> {userDetails?.address?.area || 'N/A'}</p>
          <p><strong>Pincode:</strong> {userDetails?.address?.pincode || 'N/A'}</p>

        </div>
      ) : (
        <p>Loading user details...</p>
      )}
    </Modal>
  );

  // Modal to display property details
  const propertyDetailsModal = (
    <Modal
      visible={!!propertyDetails}
      title="Property Details"
      onCancel={() => setPropertyDetails(null)}
      footer={null}
    >
      {propertyDetails ? (
        <div>
          {/* <h3>Property Information</h3> */}
          <p><strong>ID:</strong> {propertyDetails._id}</p>
          <p><strong>Type:</strong> {propertyDetails.type}</p>
          <p><strong>Location:</strong> {propertyDetails.location}</p>
          <p><strong>Rent:</strong> {propertyDetails.rent}</p>
          <p><strong>Size:</strong> {propertyDetails.size}</p>
          <p><strong>Area:</strong> {propertyDetails.area}</p>
          <p><strong>Advance:</strong> {propertyDetails.advance}</p>
          <p><strong>Building:</strong> {propertyDetails.building}</p>
          <p><strong>Photos:</strong></p>
          <img src={`${hosturl}/${propertyDetails.photos[0]}`} alt="Property" style={{ maxWidth: '100%' }} />
        </div>
      ) : (
        <p>Loading property details...</p>
      )}
    </Modal>
  );

  return (
    <div style={{ margin: '20px' }}>
      <h4 style={{ marginBottom: '20px' }}>Occupancy Requests</h4>
      <Table
        columns={columns}
        dataSource={occupancyRequests}
        rowKey="_id"
        pagination={false}
        bordered
        loading={loading}
        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
      />
      {userDetailsModal}
      {propertyDetailsModal}
    </div>
  );
};

export default OccupancyRequests;
